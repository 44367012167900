import { Component, useEffect } from "react";

export class AlertNotification extends Component{
    constructor(props) {
        super(props);

        this.state = {
            show:false,
            component:null,
            size:null,
        }
    }

    showAlert(title,type,component,size){
        this.setState({
            show:true,
            title:title,
            type:type,
            component:component,
            size:size,
        });

        setTimeout(() => {
            this.setState({
                show:false,
            });
        }, 2500);
    }

    hideAlert(){
        this.setState({
            show:false,
        });
    }


    render(){
        return this.state.show&&(
            <div ref={this.props.ref} className={this.state.type==="fail"?"alert-notification alert-fail":"alert-notification alert-success"}>
                <div>{this.state.title}</div>
            </div>
        );
    }
}