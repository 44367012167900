const panelProps = {

};
export const Panel=({options,title,children})=>{
    return (
        <div className="panel">
            {options?.noHeader?null:
            <div className="panel-header">
                {title&&<div className="panel-title">{title??null}</div>}
                <div className="panel-buttons"></div>
            </div>
            }
            <div className="panel-body">{children}</div>
        </div>
    );
}