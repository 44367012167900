import "../sass/main.scss";
import { ImageLoader, products } from "../constants";
import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "../components/Modal";
import { Cart } from "../views/Cart";
import axios from "axios";
import { BASE_URL } from "../constants";
import { ConfirmModal } from "./ConfirmModal";
import { TextField } from "./Inputs";
import { LogInByPhone } from "../views/LogInByPhone";
import { Orders } from "../views/Orders";
import { LuListOrdered, LuLock, LuLogOut, LuPhone, LuShoppingBag, LuShoppingCart } from "react-icons/lu";
export const Layout = (props) => {
    const navigate = useNavigate();
    const modal = useRef();
    const confirm = useRef();
    const [_phone, _setPhone] = useState();
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState([]);
    const [seller_deatils, setSellerDetails] = useState({});

    async function getCarts() {
        await axios.get(BASE_URL + "/carts?phone=" + localStorage.getItem("phone")).
            then((resp) => {
                setCart(resp.data);
                setLoading(false);
            }).
            catch((error) => {
                console.log(error.message);
            })
    }

    async function getSellerDetails() {
        await axios.get(BASE_URL + "/auth/seller-details").
            then((resp) => {
                setSellerDetails(resp.data);
                setLoading(false);
            }).
            catch((error) => {
                console.log(error.message);
            })
    }

    useEffect(() => {
        typeof props.viva != "undefined" && getCarts() && getSellerDetails();

        // if (loading) {
        //     getSellerDetails();
        // }
    }, [loading, props.viva])
    return (
        <div className="w-[100%] h-[100%] flex flex-col">
            <Modal ref={modal} />
            <ConfirmModal ref={confirm} />
            <div className="grow">
                <div className="fixed bg-white z-50 w-full text-white shadow-lg md:bg-white md:text-slate-600 md:flex md:justify-between">
                    <div className="flex justify-between px-3">
                        <span className={"text-pink-600 font-bold my-auto px-3 text-[1rem] md:text-[2rem]"}>
                            <Link to="/">E-Shop
                            </Link>
                        </span>
                        {JSON.parse(localStorage.getItem("user"))?.role && <span className="font-light text-slate-400 my-3 capitalize">{JSON.parse(localStorage.getItem("user")).role.toUpperCase()}</span>}
                    </div>
                    <div className="w-12/12 sm:w-5/12 md:w-7/12 flex justify-end items-end text-[13px] md:text-[1rem]">
                        {localStorage.getItem("phone") && <div className="px-3 md:px-0 flex flex-col justify-center items-center w-4/12 text-pink-600 font-mono cursor-pointer" onClick={() => {
                            if (!localStorage.getItem("phone")) {
                                modal.current.showModal("Login", <LogInByPhone />
                                    , "w-10/12 sm:w-[100%] md:w-2/12");
                                return;
                            }
                            modal.current.showModal(
                                <b className="text-pink-600 font-mono">Cart</b>,
                                <Cart setCart={setCart}
                                    viva={props.viva} />
                                , "w-10/12 sm:w-[100%] md:w-5/12");
                        }}><LuShoppingCart /> <span><span className="mr-2">Cart</span> <span className="text-[11px] absolute border border-slate-500 bg-slate-200 text-slate-800 font-bold px-1 rounded-full">{cart.length}</span></span>
                            {/* {localStorage.getItem("phone") && cart.length > 0 && <sup className="absolute inset-y-0 top-2 border border-slate-500 bg-slate-200 text-slate-800 font-bold py-3 px-2 rounded-full">{cart.length}</sup>} */}
                        </div>}

                        {localStorage.getItem("phone") && <div className="flex flex-col justify-center items-center w-4/12 text-pink-600 px-3 md:px-0 cursor-pointer" onClick={() => {
                            if (!localStorage.getItem("phone")) {
                                modal.current.showModal("Login", <LogInByPhone />
                                    , "w-10/12 sm:w-[100%] md:w-2/12");
                                return;
                            }
                            // modal.current.showModal(
                            //     <b className="text-pink-600 font-mono">Orders/Oda</b>,
                            //     <Orders status={"group"} />
                            //     , "w-10/12 sm:w-[100%] md:w-6/12");
                            navigate('/orders');
                        }}><LuShoppingBag /> <span>Orders</span></div>}

                        {


                            <>{localStorage.getItem("phone") &&
                                <div className="flex flex-col justify-center items-center w-4/12 text-slate-400">
                                    <LuPhone />
                                    <span>{localStorage.getItem("phone")}</span>
                                </div>}

                                {!localStorage.getItem("phone") &&

                                    <div className="flex flex-col justify-center items-center w-4/12 text-pink-600 font-mono cursor-pointer" onClick={() => {
                                        if (!localStorage.getItem("phone")) {
                                            modal.current.showModal("Login", <LogInByPhone />
                                                , "w-10/12 sm:w-[100%] md:w-2/12");
                                        }
                                        setLoading(true);
                                    }}><LuLock /> <span>Login</span></div>}
                            </>

                        }

                        {localStorage.getItem("phone") && <div className="px-3 md:px-0 w-4/12 flex  flex-col justify-center items-center text-pink-600 font-bold cursor-pointer" onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                        }}><LuLogOut /> <span>Log-Out</span></div>}
                    </div>
                </div>
            </div>

            <div className="grow h-5/5 bg-white border border-slate-100 rounded-lg m-5 my-[100px] md:my-[3%] p-5">
                {/* {React.cloneElement(<>{props.children}</>,{...props})} */}
                {props.children}
            </div>


            {seller_deatils?.phone && <div className="flex justify-center mb-5">
                <span className="text-pink-600 font-bold font-mono px-5">Direct Contact/Phone (Mawasiliano): +255 {seller_deatils?.phone}</span>
            </div>}
        </div>
    );
}