import axios from "axios";
import { TextField } from "../components/Inputs";
import { Panel } from "../components/Panel";
import { Table } from "../components/Table";
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../constants";
import { AlertNotification } from "../components/AlertNotification";
import { ConfirmModal } from "../components/ConfirmModal";

export const NewBatch = (props) => {
    const alert = useRef();
    const confirm = useRef();
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [batches, setBatches] = useState([]);
    const [links, setLinks] = useState([]);
    const [itemValues, setItemValues] = useState({});

    async function submitBatch(e) {
        let data = new FormData();
        data.append("item_id", props?.item.item_id);
        data.append("batch_no", itemValues?.batch_no);
        data.append("quantity", itemValues?.quantity);
        data.append("price", itemValues?.price);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BASE_URL + '/items/add-batch',
            headers: { 'Authorization': 'Bearer ', 'Content-Type': 'application/json' },
            data: data
        };
        await axios.request(config).
            then((resp) => {
                alert.current.showAlert(resp.data.message, resp.data.status);
                setLoading(true);
                typeof props.setLoading != "undefined" && props.setLoading(true);
            })
            .catch((error) => {
                console.error(error?.message);
            });
    }

    async function getBatches() {
        await axios.get(BASE_URL + `/items/batches?page=${pagination.page}&limit=${pagination.limit}&item_id=${props.item.item_id}
        `)
            .then((resp) => {
                setBatches(resp.data.data);
                setLinks(resp.data.links);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error?.message);
            });
    }

    async function deleteItem(item) {
        axios.delete(BASE_URL + "/items/delete-batch/" + item.item_batch_id)
            .then((resp) => {
                setLoading(true);
            })
            .catch((error) => {
                console.error(error?.message);
            })
    }

    useEffect(() => {
        if (loading) {
            getBatches();
        }
    }, [loading]);

    return (
        <div className="md:flex md:justify-between gap-2">
            <div className="flex-grow-0 w-[100%] sm:w-[100%] md:w-8/12 mb-3">
                <AlertNotification ref={alert} />
                <ConfirmModal ref={confirm} />
                <Panel
                    title={"Batch Details"}
                >
                    <form onSubmit={(e) => {
                        submitBatch(e);
                        e.preventDefault();
                    }}>
                        <div className="grid grid-cols-2 gap-2 mb-3">
                            <div>
                                <label htmlFor="">Batch No</label>
                                <TextField className={"w-[100%]"} name={"batch_no"} placeholder={"Batch No"} required={true} onChange={(e) => {
                                    e.currentTarget.value = e.currentTarget.value.toString().toUpperCase();
                                    setItemValues({ ...itemValues, batch_no: e.currentTarget.value.toString().toUpperCase() });
                                }} />
                            </div>
                            <div>
                                <label htmlFor="">Quantity</label>
                                <TextField className={"w-[100%]"} name={"quantity"} placeholder={"Quantity"} required={true} onChange={(e) => {
                                    e.currentTarget.value = Number.isNaN(parseFloat(e.currentTarget.value)) ? '' : e.currentTarget.value.replace(/[^0-9.]/g, '');
                                    setItemValues({ ...itemValues, quantity: e.currentTarget.value });
                                    
                                }} />
                            </div>
                        </div>
                        <div className="flex justify-center mb-3">
                            <button className="btn-primary">Submit</button>
                        </div>
                    </form>
                </Panel>
            </div>
            <div className="flex-grow md:w-8/12 mb-3 overflow-auto">
                <Table
                    columns={[
                        {
                            name: "index",
                            label: "SN",
                        },
                        {
                            name: "batch_no",
                            label: "Batch No",
                        },
                        {
                            name: "quantity",
                            label: "Quantity",
                        },
                        {
                            name: "remaining_quantity",
                            label: "Remaining Quantity",
                        },
                    ]}
                    items={batches}
                    customColumns={(item) => {
                        return [
                            {
                                column: "...",
                                component: (
                                    <React.Fragment>
                                        <button className="btn-danger" onClick={() => {
                                            confirm.current.showConfirmModal("", "", () => {
                                                deleteItem(item);
                                                alert.current.showAlert("Batch Removed Successful", "success");
                                            });
                                        }}>Delete</button>
                                    </React.Fragment>
                                ),
                            }
                        ];
                    }}
                    withPagination={true}
                    setPagination={setPagination}
                    links={links}
                    setLoading={setLoading}
                />
            </div>
        </div>
    );
}