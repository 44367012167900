import { LuDelete, LuMinusCircle, LuPlusCircle, LuX } from "react-icons/lu";
import { BASE_URL, ImageLoader } from "../constants";
import axios from "axios";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { TextField } from "../components/Inputs";
import { AlertNotification } from "../components/AlertNotification";
import { ConfirmModal } from "../components/ConfirmModal";
import { Table } from "../components/Table";
import { Modal } from "../components/Modal";
import { Layout } from "../components/Layout";
import { Navigate, useNavigate } from "react-router-dom";
import { Spinner } from "../components/Spinner";

export const Orders = (props) => {
    const logged_user = JSON.parse(localStorage.getItem("user"));
    const alert = useRef();
    const confirm = useRef();
    const modal = useRef();
    const navigate = useNavigate();
    const textField = useRef();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [phone, setPhone] = useState();
    const [orderConfirmed, setOrderConfirmed] = useState(false);
    const [btnOrderConfirmClicked, setBtnOrderConfirmClicked] = useState(false);
    const ordered_at = props.item?.ordered_at ? '&ordered_at=' + props.item?.ordered_at : '';
    const [orderTotal, setOrderTotal] = useState(0);

    async function getOrders() {
        await axios.get(BASE_URL + `/orders?status=${props?.status}&order_detail_id=${props.item?.order_detail_id}` + ordered_at + `&phone=` + (logged_user?.role != "seller" ? localStorage.getItem("phone") ?? '' : props.item?.phone ?? '')).
            then((resp) => {
                setOrders(resp.data);

                if (props?.status == "list") {
                    let t = 0;
                    resp.data.forEach((order) => {
                        t += order.amount;
                        setOrderTotal(t);
                        console.log(t);
                    });
                }
                setLoading(false);
            }).
            catch((error) => {
                console.log(error.message);
            })
    }

    function confirmOrder() {
        setBtnOrderConfirmClicked(true);
        let data = {
            order_detail_id: props.item?.order_detail_id,
            orders: orders,
        };

        axios.post(BASE_URL + '/orders/confirm-order', data)
            .then((resp) => {
                //typeof props.setLoading != "undefined" && props.setLoading(true);
                typeof props.viva != "undefined" && props.viva();
                setOrderConfirmed(true);
                setBtnOrderConfirmClicked(false);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    async function removeOrder(order_id) {
        await axios.delete(BASE_URL + '/orders/remove-order/' + order_id)
            .then((resp) => {
                getOrders();
                props.viva();
                //setLoading(true);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    function viva() {
        getOrders();
    }

    useEffect(() => {
        if (loading) {
            getOrders();
        }
        if (!logged_user) {
            navigate('/');
        }
    }, [loading]);
    return (
        <React.Fragment>
            {
                loading ?
                <div>
                    <Spinner status={"full"} />
                </div>
                :
                <div>
                    <AlertNotification ref={alert} />
                    <ConfirmModal ref={confirm} />
                    <Modal ref={modal} />
                    <div className="flex flex-col last:border-none">
                        {/* {cart.length} */}
                        {props?.status == "list" && orders.map((i, index) =>
                            <div className="border-[1px] border-slate-300 bg-slate-100 my-1 rounded-md p-3 w-12/12 flex justify-between">
                                <img className="object-fill h-[100px] w-2/12 rounded-md" src={ImageLoader(i.photo)} />
                                <div className="flex flex-col w-10/12 ml-2">
                                    <div className="flex justify-between">
                                        <span><b>{i.item_name}</b></span>
                                        {logged_user?.role === "seller" && !orderConfirmed && <button className="font-bold btn-danger" onClick={() => {
                                            confirm.current.showConfirmModal("", "", () => {
                                                removeOrder(i.order_id);
                                            });
                                        }}><LuX className="text-slate-800" fontSize={20} /></button>}
                                    </div>
                                    <div><span className="text-slate-400">Price (Bei):</span> <b>{i.price.toLocaleString()}</b></div>
                                    <div><span className="text-slate-400">Amount (Gharama):</span> <b>{i.amount.toLocaleString()}</b></div>
                                    <div className="grid grid-cols-2">
                                        <div><span className="text-slate-400">Quantity/Idadi </span> ({i.uom}):
                                            <b>{i?.quantity}</b></div>
                                        <div className="flex gap-1">
                                            <div className="flex">
                                                {/* <TextField
                                                    ref={textField}
                                                    className="w-12/12"
                                                    required={true}
                                                    value={i?.quantity}
                                                    readOnly={true}
                                                    onChange={(e) => {
                                                        // updateCart("change",{...i,quantity:e.currentTarget.value});
                                                    }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {props?.status == "list" &&
                            <React.Fragment>
                                <div className="flex justify-between my-3">
                                    {orders.length > 0 && <div className="my-2">
                                        <b>Grand Total: {
                                            orderTotal.toLocaleString()
                                        }</b>
                                    </div>}
                                    {btnOrderConfirmClicked ?
                                        <div>
                                            <Spinner />
                                        </div>
                                        : props.item?.status != "submitted" && logged_user?.role == "seller" && !orderConfirmed &&
                                        <button className="btn-primary" onClick={() => {
                                            confirmOrder();
                                        }}>Confirm Order</button>}
                                </div>
                            </React.Fragment>
                        }
                        {props?.status == "group" &&
                            <Layout viva={viva}>
                                <div className="overflow-auto">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                                        {!orders.length && <div>No orders found</div>}
                                        {orders.map((order) =>
                                            <div className="bg-slate-100 hover:bg-slate-200 border border-slate-300 hover:border-slate-400 cursor-pointer shadow-md rounded-md p-2" onClick={(e) => {
                                                console.log(order);
                                                modal.current.showModal(
                                                    <b className="text-pink-600 font-mono">Orders</b>,
                                                    <Orders
                                                        status={"list"}
                                                        item={order}
                                                        setLoading={setLoading}
                                                        viva={viva}
                                                    />
                                                    , "w-10/12 sm:w-[100%] md:w-6/12");
                                            }}>
                                                <div><span className="text-slate-500">Date: </span> <span className="text-pink-600">{order.ordered_at}</span></div>
                                                <div><span className="text-slate-500">Amount: </span> <span>{order.amount.toLocaleString()}</span></div>
                                                <div><span className="text-slate-500">Phone: </span> <span>(0){order.phone}</span></div>
                                                <div><span className="text-slate-500">Status: </span> <span className={order.status == "submitted" ? "text-green-600" : "text-red-300"}>{order.status == "submitted" ? "Confirmed" : "Not Confirmed"}</span></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Layout>}
                    </div>
    
                </div>
            }
        </React.Fragment>
    );
}