
import { useState } from "react";
import { LuChevronLeft, LuChevronRight, LuChevronsLeft, LuChevronsRight } from "react-icons/lu";

export const Pagination = (props) => {
    const [active, setActive] = useState();
    function paginationFilterization(label) {
        let name;
        if (label === "First") {
            name = <LuChevronsLeft size={"20px"} />;
        } else if (label === "Previous") {
            name = <LuChevronLeft size={"20px"} />;
        } else if (label === "Next") {
            name = <LuChevronRight size={"20px"} />;
        } else if (label === "Last") {
            name = <LuChevronsRight size={"20px"} />;
        } else {
            name = <dic className="px-1">{label}</dic>;
        }
        return name;
    }

    return (
        <div className="pagination flex justify-center">
            {props.links.map((link) =>
                <div className={"pagination-item" + (link.label === active ? " active" : "")} onClick={() => {

                    try {
                        setActive(link.label);
                        let page = new URL(link.url)?.searchParams.get('page');
                        let limit = new URL(link.url)?.searchParams.get('limit');
                        props.setPagination({ page: page, limit: limit });
                        props.setLoading(true);
                    } catch (error) {

                    }
                }}>{paginationFilterization(link.label)}</div>
            )}

        </div>
    );
}