export const TextField = (props) => {
  let className = 'text-field ';
  className += props.className;
  // if (props.required) {
  //   className += " text-field-required";
  // }
  if (props.readOnly) {
    className += " cursor-not-allowed";
  }
  return (
    <div>
      <input
        ref={props.ref}
        type={props.type ?? "text"}
        name={props.name}
        value={props.value}
        accept={props.accept}
        required={props.required}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        className={className}
        onChange={(e) =>
          typeof props.onChange != "undefined" && props.onChange(e)
        }
        onKeyUp={(e) =>
          typeof props.onKeyUp != "undefined" && props.onKeyUp(e)
        }
        onMouseEnter={(e) =>
          typeof props.onMouseEnter != "undefined" && props.onMouseEnter(e)
        }
      />
    </div>
  );
};

export const TextAreaField = () => {
  return (
    <div>
      <textarea></textarea>
    </div>
  );
};
