import { LuDelete, LuMinusCircle, LuPlusCircle, LuX } from "react-icons/lu";
import { BASE_URL, ImageLoader } from "../constants";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { TextField } from "../components/Inputs";
import { AlertNotification } from "../components/AlertNotification";
import { ConfirmModal } from "../components/ConfirmModal";
import { Spinner } from "../components/Spinner";

export const Cart = (props) => {
    const alert = useRef();
    const confirm = useRef();
    const textField = useRef();
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState([]);
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [btnSendOrderClicked, setBtnSendOrderClicked] = useState(false);
    async function updateCart(action, item) {
        if (item.quantity <= 0) {
            item.quantity = 0;
        }
        let data = {
            ...item,
            action: action,
        };
        await axios.post(BASE_URL + '/carts/update-cart', data).then((resp) => {
            props.viva();
            getCarts();
            // setLoading(true);
        }).catch((error) => { })
    }

    async function getCarts() {
        await axios.get(BASE_URL + "/carts?phone=" + localStorage.getItem("phone")).
            then((resp) => {
                setCart(resp.data);
                setLoading(false);

            }).
            catch((error) => {
                console.log(error.message);
            })
    }

    async function removeCart(cart_id) {
        await axios.delete(BASE_URL + '/carts/remove-cart/' + cart_id)
            .then((resp) => {
                getCarts();
                props.viva();
                //setLoading(true);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    async function submitOrder(e) {
        setBtnSendOrderClicked(true);
        let data = {
            phone: localStorage.getItem("phone"),
        }
        for (let index = 0; index < 2; index++) {
            await axios.post(BASE_URL + '/orders', data)
                .then((resp) => {
                    setCart([]);
                    // getCarts();
                    props.viva();
                    // setLoading(true);
                    setBtnSendOrderClicked(false);
                });
        }
        // e.preventDefault();
    }

    useEffect(() => {
        if (loading) {
            getCarts();
        }
    }, [loading]);
    return (
        <div>
            <AlertNotification ref={alert} />
            <ConfirmModal ref={confirm} />
            <div className="flex flex-col last:border-none">
                {/* {cart.length} */}
                {loading ?
                    <div>
                        <Spinner status={"full"} />
                    </div>
                    : cart.length<=0 ? 
                    <div className="text-slate-500 w-full items-center">
                        <span className="m-auto">No Cart</span>
                    </div>
                        : cart.map((i, index) =>
                            <div className="border-[1px] overflow-auto border-slate-300 bg-slate-100 my-1 rounded-md p-3 w-12/12 flex justify-between">
                                <img className="object-fill h-[100px] w-2/12 rounded-md" src={ImageLoader(i.photo)} />
                                <div className="flex flex-col w-10/12 ml-2">
                                    <div className="flex justify-between">
                                        <span><b>{i.item_name}</b></span>
                                        <button className="font-bold btn-danger" onClick={() => {
                                            removeCart(i.cart_id);
                                        }}><LuX className="text-slate-800" fontSize={20} /></button>
                                    </div>
                                    <div><span className="text-slate-400">Price (Bei):</span> <b>{i.price.toLocaleString()}</b></div>
                                    <div><span className="text-slate-400">Amount (Gharama):</span> <b>{i.amount.toLocaleString()}</b></div>
                                    <div className="grid grid-flow-row md:grid-cols-2">
                                        <div><span className="text-slate-400">Quantity/Idadi </span> ({i.uom}): </div>
                                        <div className="flex justify-between">
                                            <button className="text-pink-600" onClick={() => {
                                                i.quantity -= 1;
                                                //console.log(textField.current);
                                                (i.quantity >= 0) && updateCart("minus", i);
                                            }}><LuMinusCircle fontSize={"23px"} /></button>
                                            <div className="">
                                                <TextField
                                                    ref={(er) => textField.current = er}
                                                    className={"w-[100%]"}
                                                    required={true}
                                                    value={i?.quantity}
                                                    onChange={(e) => {
                                                        // console.log(parseFloat(e.currentTarget.value)+' '+parseFloat(i.quantity)+' '+parseFloat(i.remaining_quantity));
                                                        if (((parseFloat(i.remaining_quantity) + parseFloat(i.quantity)) - parseFloat(e.currentTarget.value)) < 0) return;
                                                        // if(parseFloat(i.remaining_quantity)==0 && (parseFloat(e.currentTarget.value)>parseFloat(i?.quantity))) return;
                                                        // if(parseFloat(i.remaining_quantity)==0) return;
                                                        if (e.currentTarget.value < 0) e.currentTarget.value = Math.abs(e.currentTarget.value);

                                                        updateCart("change", { ...i, quantity: e.currentTarget.value });
                                                    }} /></div>
                                            <button className="text-pink-600" onClick={() => {
                                                i.quantity += 1;
                                                (parseFloat(i.remaining_quantity) > 0) && updateCart("plus", i);
                                            }}><LuPlusCircle fontSize={"23px"} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                {/* <form onSubmit={(e) => {
                    submitOrder(e);
                }}> */}
                {cart.length > 0 && <div className="md:flex md:justify-between mt-3">
                        <TextField className="mb-3 md:mb-0 w-[100%]" required={true} readOnly={true} placeholder={"Phone/Simu"} value={localStorage.getItem("phone")} onChange={(e) => {
                            setPhone(e.currentTarget.value);
                        }} />
                        {btnSendOrderClicked ?
                    <div>
                        <Spinner />
                    </div>
                    : <button className="btn-primary mb-3 md:mb-0 w-[100%] md:w-auto" onClick={(e) => {
                            submitOrder(e);
                        }}>Send Order</button>}
                    </div>}
                {/* </form> */}
            </div>
        </div>
    );
}