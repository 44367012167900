import { Component } from "react"

export class ConfirmModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    showConfirmModal(title, message, confirmAction) {
        this.setState({
            show: true,
            title:title,
            message:message,
            confirmAction: confirmAction,
        });
    }

    hideConfirmModal() {
        this.setState({
            show: false,
        });
    }

    render() {
        return this.state.show ? (<div className="confirm-modal-container">
            <div className="confirm-modal">
                <div className="confirm-modal-header">
                    <div>Confirm</div>
                    <div>
                        <button className="modal-close-button"
                            onClick={() => this.hideConfirmModal()}
                        >X</button>
                    </div>
                </div>
                <div className="confirm-modal-body">
                    <div>
                        <div>{this.state.message?this.state.message:`Are you sure to perform this action`}</div>
                        <div className="buttons">
                            <button className="btn-default"
                                onClick={() => this.hideConfirmModal()}
                            >Close</button>
                            <button className="btn-primary"
                                onClick={() => { typeof this.state.confirmAction != "undefined" && this.state.confirmAction(); this.hideConfirmModal(); }}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>) : null;
    }
}