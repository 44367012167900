import logo from './logo.svg';
import './App.css';
import { Dashboard } from './views/Dashboard';
import { Router, Routes,Route, BrowserRouter } from 'react-router-dom';
import { NotFound } from './views/Not-Found';
import { Items } from './views/Items';
import { Orders } from './views/Orders';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    
    <BrowserRouter>
    {/* <Router> */}
      <Routes>
        <Route path={"/"} element={<Dashboard/>}/>
        <Route path={"/items"} element={<Items/>}/>
        <Route path={"/orders"} element={<Orders status={"group"}/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Routes>
    {/* </Router> */}
    </BrowserRouter>
  );
}

export default App;
