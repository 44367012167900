import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../components/Layout";
import { Table } from "../components/Table";
import { Modal } from "../components/Modal";
import { NewItem } from "./New-item";
import axios from "axios";
import { BASE_URL, ImageLoader } from "../constants";
import { AlertNotification } from "../components/AlertNotification";
import { ConfirmModal } from "../components/ConfirmModal";
import { Pagination } from "../components/Pagination";
import { NewBatch } from "./New-Batch";
import { Spinner } from "../components/Spinner";

export const Items = (props) => {
    const alert = useRef();
    const modal = useRef();
    const confirm = useRef();
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [items, setItems] = useState([]);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);

    const getItems = () => {
        axios.get(BASE_URL + `/items?action=items&page=${pagination.page}&limit=10&status=item`)
            .then((resp) => {
                setItems(resp.data.data);
                setLinks(resp.data.links);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error?.message);
            })
    }

    const deleteItem = async (item) => {
        await axios.delete(BASE_URL + "/items/delete-item/" + item.item_id)
            .then((resp) => {
                setLoading(true);
            })
            .catch((error) => {
                console.error(error?.message);
            });
    }

    useEffect(() => {
        if (loading) {
            getItems();
        }
    });
    return (
        <Layout viva={() => { }}>
            <div className="overflow-auto">
                <Modal ref={modal} />
                <AlertNotification ref={alert} />
                <ConfirmModal ref={confirm} />
                <div className="flex justify-end">
                    <button className="btn-primary my-2" onClick={() => {
                        modal.current.showModal("Add New Item", <NewItem setLoading={setLoading} />, "w-10/12 sm:w-[100%] md:w-8/12");
                    }}>Add New Item</button>

                </div>
                {loading ? <Spinner /> :
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                        {items.map((item) =>
                            <div className="bg-slate-100 hover:bg-slate-200 border border-slate-300 hover:border-slate-400 cursor-pointer shadow-md rounded-md p-2" onClick={(e) => { }}>
                                <div className="flex flex-row gap-2">
                                    <div className="px-3">
                                        <img className="rounded-md h-[100px]" src={ImageLoader(item.photo)} />
                                    </div>
                                    <div>
                                    <div className="flex flex-col">
                                        <div>
                                            <span className="text-slate-500">Date: </span> <span className="text-pink-600">{item.item_name}</span></div>
                                        <div><span className="text-slate-500">UOM: </span> <span>{item.uom}</span></div>
                                        <div><span className="text-slate-500">Price: </span> <span>{item.price.toLocaleString()}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-center gap-2 p-1">
                                        <button className="btn-default" onClick={() => {
                                            modal.current.showModal("Edit Item", <NewItem setLoading={setLoading} item={item} action="update" />, "w-10/12 sm:w-[100%] md:w-8/12");
                                        }}>Edit</button>
                                        {item?.remaining_quantity <= 0 && <button className="btn-danger" onClick={() => {
                                            confirm.current.showConfirmModal("", "", () => {
                                                deleteItem(item);
                                                alert.current.showAlert("deleted", "fail");
                                            });
                                        }}>Delete</button>}
                                        <button className="btn-primary" onClick={() => {
                                            modal.current.showModal(`Add Batch [${item.item_name}-${item.uom}]`,
                                                <React.Fragment>
                                                    <NewBatch item={item} setLoading={setLoading} />
                                                </React.Fragment>
                                                , "w-10/12 sm:w-[100%] md:w-8/12");
                                        }}>Add Batch</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                <Pagination
                    setPagination={setPagination}
                    links={links}
                    setLoading={setLoading}
                />
                {/* {!loading && <Table
                    columns={[
                        {
                            name: "index",
                            label: "SN",
                        },
                        {
                            name: "item_name",
                            label: "Item Name",
                        },
                        {
                            name: "uom",
                            label: "UOM",
                        },
                        {
                            name: "price",
                            label: "Price",
                        },
                        {
                            name: "remaining_quantity",
                            label: "Remaining Quantity(Stock)",
                        },
                        {
                            name: "photo",
                            label: "Photo",
                            options: {
                                type: "image"
                            }
                        },
                    ]}
                    items={items}
                    customColumns={(item) => {
                        return [
                            {
                                column: "Actions",
                                component: <div className="flex justify-center gap-2 p-1">
                                    <button className="btn-default" onClick={() => {
                                        modal.current.showModal("Edit Item", <NewItem setLoading={setLoading} item={item} action="update" />, "w-10/12 sm:w-[100%] md:w-8/12");
                                    }}>Edit</button>
                                    {item?.remaining_quantity <= 0 && <button className="btn-danger" onClick={() => {
                                        confirm.current.showConfirmModal("", "", () => {
                                            deleteItem(item);
                                            alert.current.showAlert("deleted", "fail");
                                        });
                                    }}>Delete</button>}
                                    <button className="btn-primary" onClick={() => {
                                        modal.current.showModal(`Add Batch [${item.item_name}-${item.uom}]`,
                                            <React.Fragment>
                                                <NewBatch item={item} setLoading={setLoading} />
                                            </React.Fragment>
                                            , "w-10/12 sm:w-[100%] md:w-8/12");
                                    }}>Add Batch</button>
                                </div>
                            }
                        ]
                    }}
                    withPagination={true}
                    setPagination={setPagination}
                    links={links}
                    setLoading={setLoading}
                />} */}
            </div>
        </Layout>
    );
}