import { React, useState } from "react";
import { Modal } from "./Modal";
import { Pagination } from "./Pagination";
import { BASE_URL, ImageLoader } from "../constants";

export const Table = (props) => {
    // const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [showModal, setShowModal] = useState(false);
    let column_index = 0;

    function columnFunc(column, items, item) {
        if (column.name === "index") {
            return typeof item.options == "object" && !item.options.index ?
                null : ++column_index;
        } else {
            if (column.options?.type == "image") { 
                return <>
                <img className="rounded-md w-[130px] h-[75px]" src={ImageLoader(item[column.name])}/>
                </>; 
            }
            
            let alt_cell = (column.options?.alt_cell[item[column.name]]??'');
            return alt_cell?
            alt_cell?.cell:
            item[column.name];
        }
    }

    function totalCalcFunc(items) {
        let total = [];
        let column_length = 0;
        props.options.total_column.forEach((tc) => {
            total[tc] = 0;
        })
        items.forEach((item) => {
            props.options.total_column.forEach((tc) => {
                total[tc] += parseFloat(item[tc]);
            });
        });
        return <tr>
            {
                props.columns.map((cl) => <td><b>{props.options.total_column.filter((tc) => {
                    if (tc == cl.name) return true;
                    return false;
                }).map((tc) => total[tc])}
                    {cl.name == "index" ? "Total" : null}
                </b></td>)
            }
        </tr>
    }

    return (
        <div className={`${props.className}`}>
            <Modal showModal={showModal} setShowModal={setShowModal} />
            <table className={`table`}>
                <thead>
                    <tr>
                        {typeof props.columns != "undefined" && props.columns.map((e, i) => <th key={i}>{e.label}</th>)}
                        {typeof props.customColumns == "function" && typeof props.customColumns() == "object" && props.customColumns().length > 0 && (props.customColumns()).map((customInput) =>
                            <th>{customInput.column}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {typeof props.items != "undefined" && props.items.map((item, index) =>
                        <>
                            <tr key={index} className={`table-data-row ${typeof props.onClick == "undefined" ? "" : "row-selected"} ${typeof props.rowChild == "undefined" ? "" : " with-row-child"}`} onClick={(e) => {
                                if (props.onClick) {
                                    props.onClick(e, item);
                                }
                            }}>
                                {
                                    typeof props.columns != "undefined" && props.columns.map((column) =>
                                        <td key={column_index * Math.random()}>{columnFunc(column, props.items, item)}</td>
                                    )
                                }
                                {typeof props.customColumns == "function" && typeof props.customColumns() == "object" && (props.customColumns()).length > 0 && (props.customColumns(item)).map((customInput) =>
                                    <td>{customInput.component}</td>)}
                            </tr>
                            {typeof props.rowChild != "undefined" && <tr><td colSpan={props.columns.length}>
                                {props.rowChild}
                            </td></tr>}
                            {typeof props.options != "undefined" && props.items.length == column_index && totalCalcFunc(props.items)}
                        </>
                    )}
                </tbody>
            </table>

            {props.withPagination && typeof props.setLoading != "undefined" && typeof props.links != "undefined" && typeof props.setPagination != "undefined" &&
                <Pagination
                    setPagination={props.setPagination}
                    links={props.links}
                    setLoading={props.setLoading}
                />}
        </div>
    );
}