import { Component, useCallback, useEffect, useState } from "react";

export class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show:false,
            component:null,
            size:null,
        }
    }

    showModal(title,component,size){
        this.setState({
            show:true,
            title:title,
            component:component,
            size:size,
        });
    }

    hideModal(){
        this.setState({
            show:false,
        });
    }
    render() {
        return this.state.show?(
            <div ref={this.props.ref}
                className="modal-container">
                <div className={`modal ${this.state.size??"w-6/12"}`}>
                    <div className="modal-header">
                        <div className="modal-title"> {this.state.title} </div> 
                        <div className="modal-buttons">
                            <button className="modal-close-button"
                                onClick={
                                    () => this.hideModal()
                                }>
                                X </button> 
                        </div> 
                    </div> 
                    <div className="modal-body"> {this.state.component} </div>
                </div>
            </div>
        ):null;
    }
}